// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.main-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

}

navbarBehaviour();


jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );


    // Funzione per controllare la larghezza della finestra e chiudere il modal se necessario
    function chiudiModalSotto992px() {
        // Verifica se la larghezza della finestra è inferiore a 992px
        if (window.innerWidth < 992) {
            // Chiudi il modal
            $('#menuModal').modal('hide'); // Sostituisci 'idModal' con l'id del tuo modal
        }
    }

// Aggiungi un listener per l'evento di ridimensionamento della finestra
    window.addEventListener('resize', chiudiModalSotto992px);


}); // jQuery End


const swiper_default = new Swiper('.swiper--default', {
    loop: false,
    // autoplay: {
    //     delay: 3000,
    // },
    spaceBetween: 0,
    speed: 600,
    slidesPerView: 1,
});

